import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import {
  SEO,
  Text,
  Flex,
  Box,
  Button,
  BackgroundImage,
  Input
} from "components";
import { useIntl } from "hooks";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Background = styled.div`
  height: 100vh;
  min-width: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  overflow: hidden;
`;

const Overlay = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
`;

const TextFlex = styled(Flex)`
  border-radius: 9px;
`;

const Feature = styled.div`
  max-width: 800px;
  color: ${p => p.theme.colors.black};
  text-align: center;
  margin-bottom: 40px;
  h2 {
    margin: 15px 0;
    &::first-letter {
      color: ${p => p.theme.colors.brand};
    }
  }
  p {
    line-height: 1.4;
    max-width: 600px;
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const IndexPage = () => {
  const locale = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: contentfulContact {
            background {
              fluid(maxWidth: 2000) {
                sizes
                src
                srcSet
              }
            }
          }
          nl: contentfulContact(node_locale: { regex: "/nl/" }) {
            title
            form
            button
          }
          en: contentfulContact(node_locale: { regex: "/en/" }) {
            title
            form
            button
          }
        }
      `}
      render={data => {
        console.log(data);
        const content = data[locale];
        return (
          <>
            <SEO title="Contact" />
            <Background>
              <BackgroundImage
                durationFadeIn={300}
                loading="eager"
                fluid={data.placeholderImage.background.fluid}
              />
              <Overlay />
            </Background>
            <Flex
              width="100%"
              mt="200px"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text mb="20px" as="h1" fontSize={{ _: "50px", sm: "70px" }}>
                {content.title}
              </Text>
              <TextFlex
                width={{ _: "calc(100% - 16px)", sm: "600px" }}
                flexDirection="column"
                p={{ _: "16px 8px", sm: "30px" }}
                bg="rgba(255,255,255,1)"
                mt="0"
                mb="200px"
              >
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <p hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" />
                    </label>
                  </p>
                  <p />
                  <input type="hidden" name="form-name" value="contact" />
                  <Input required name="name" placeholder={content.form[0]} />
                  <Input
                    required
                    type="phone"
                    name="phone"
                    placeholder={content.form[1]}
                  />
                  <Input
                    required
                    type="email"
                    name="email"
                    placeholder={content.form[2]}
                  />
                  <Input
                    required
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder={content.form[3]}
                  />
                  <Button type="submit" py="12px" mt="40px" secondary>
                    {content.button}
                  </Button>
                </form>
              </TextFlex>
            </Flex>
          </>
        );
      }}
    />
  );
};

export default IndexPage;
